import React from "react";
import "./Services.scss";

class Services extends React.Component {
  state = {
    isHovering: [],
    hover: false,
    services_in_view: 0,
  };

  componentDidMount() {
    this.setState(() => {
      this.isHovering = [false];
    });
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScrollChange);
    }
  }

  handleScrollChange = (event) => {
    if (
      window.scrollY >= document.body.clientHeight / 14 &&
      this.state.services_in_view === 0
    ) {
      this.setState({ services_in_view: 1 });
    }
  };

  handleMouseOver = (index) => {
    console.log(index);
    const hoveringStates = this.state.isHovering;
    hoveringStates[index] = true;

    this.setState({
      isHovering: hoveringStates,
      hover: true,
    });
  };

  handleMouseOut = (index) => {
    console.log(index);
    const hoveringStates = this.state.isHovering;
    hoveringStates[index] = false;

    this.setState({
      isHovering: hoveringStates,
      hover: true,
    });
  };

  render() {
    return (
      <>
        {!this.isMobile && (
          <div className="services-heading">
            <div className="services-img-section">
              <div className="outer-frame">
                <div
                  className="bg bg-spray service-item"
                  id="spray-foam"
                  onMouseOver={() => this.handleMouseOver(1)}
                  onMouseOut={() => this.handleMouseOut(1)}
                >
                  <h3>SPRAY FOAM</h3>
                  <p className="service-description noselect">
                    Spray foam insulation is applied as a liquid that expands
                    into a foam, creating a tight seal in every corner and
                    crevice. This airtight barrier provides excellent energy
                    efficiency and soundproofing, making it ideal for reducing
                    air leakage and improving overall comfort. We provide
                    detailed explanations about the benefits, applications, and
                    installation process of spray foam insulation, along with
                    tips and insights on choosing the right type of spray foam
                    for your project.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-insulation service-item"
                  id="insulation"
                  onMouseOver={() => this.handleMouseOver(2)}
                  onMouseOut={() => this.handleMouseOut(2)}
                >
                  <h3>BATT INSULATION</h3>
                  <p className="service-description noselect">
                    Batt insulation comes in pre-cut panels or rolls made from
                    fiberglass or other materials, designed to fit between
                    studs, joists, and rafters. It is cost-effective and easy to
                    install, providing consistent thermal protection and making
                    it a reliable choice for new construction and open wall
                    cavities. Keep your space airtight by sealing and caulking
                    to double, triple and quadruple stacks throughout your
                    property. With this combination, the comforts of being at
                    home will never change whether in the cool of winter or the
                    heat of summer.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-blow-in service-item"
                  id="insulation"
                  onMouseOver={() => this.handleMouseOver(2)}
                  onMouseOut={() => this.handleMouseOut(2)}
                >
                  <h3>BLOW-IN INSULATION</h3>
                  <p className="service-description noselect">
                    Blow-in insulation consists of loose particles that are
                    blown into spaces, effectively filling gaps and
                    hard-to-reach areas. Different materials can be used to best
                    suit the needs of your property. This method is quick to
                    install and perfect for retrofitting, offering great
                    coverage and enhancing thermal performance in existing
                    structures without major renovations.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Services;
