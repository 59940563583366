/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import "./SimpleNavbar.scss";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  NavItem,
} from "reactstrap";

class SimpleNavbar extends React.Component {
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  componentDidMount() {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // // initialise
    // headroom.init();
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  onClick = () => {
    this.toggle();
  };

  render() {
    return (
      <>
        <header>
          <Navbar
            className="navbar-main navbar-dark"
            expand="sm"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="nav-brand" to="/" tag={Link}>
                <img
                  alt="logo damasio drywall"
                  src={require("../../assets/img/logo/rv-logo.png")}
                  className="nav-logo"
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <button className="navbar-toggler" id="navbar_global">
                    <span />
                    <span />
                  </button>
                </div>
                <Nav
                  className="navbar-nav-hover align-items-lg-center ml-lg-auto"
                  navbar
                >
                  {/* NAVBAR ITEMS */}
                  <NavItem>
                    <Link smooth to="#top" className="nav-link">
                      <span className="nav-link-inner--text">Home</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link smooth to="#services" className="nav-link">
                      <span className="nav-link-inner--text">Services</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link smooth to="#about-us" className="nav-link">
                      <span className="nav-link-inner--text">About</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link smooth to="#projects" className="nav-link">
                      <span className="nav-link-inner--text">Projects</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link smooth to="#contact" className="nav-link">
                      <span className="nav-link-inner--text">Contact</span>
                    </Link>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default SimpleNavbar;
