import React from "react";
import classnames from "classnames";
import ReactWhatsapp from "react-whatsapp";
import ReactGA from "react-ga";
import GMap from "components/Utils/Map";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import "./ContactForm.scss";

class ContactForm extends React.Component {
  state = {
    contact_result_message: "",
    contact_result_color: "red",
    contact_email_valid: false,
    contact_form_valid: false,
    contact_email_style: "",
    email_name: "",
    email_address: "",
    email_message: "",
    phone_number: "",
    contact_in_view: 0,
  };
  isMobile = window.innerWidth <= 700;
  statusEnum = Object.freeze({
    success: "SUCCESS",
    fail: "FAIL",
    validEmail: "VALID_EMAIL",
    invalidEmail: "INVALID_EMAIL",
  });

  componentDidMount() {
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScrollChange);
    } else {
      this.setState({ contact_in_view: 1 });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollChange);
  }

  handleScrollChange = (event) => {
    if (
      window.scrollY >= document.body.clientHeight / 1.5 &&
      this.state.contact_in_view === 0
    ) {
      this.setState({ contact_in_view: 1 });
      window.removeEventListener("scroll", this.handleScrollChange);
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    this.setState(
      {
        [target.name]: target.value,
      },
      this.validateContactForm()
    );
  };

  handleEmailInputChange = (event) => {
    const target = event.target;

    if (target.value === "") {
      this.setState({
        contact_email_style: "",
      });
    } else if (this.validateEmail(target.value)) {
      this.setState({
        email_address: target.value,
      });
      this.onStatusChange(this.statusEnum.validEmail);
    } else {
      this.onStatusChange(this.statusEnum.invalidEmail);
    }
  };

  handlePhoneInputChange = (event) => {
    const target = event.target;
    this.setState((prevState) => ({
      phone_number: this.normalizePhoneInput(target.value, prevState.phone),
    }));
  };

  // Validates the status of the email field and the contact form send result.
  onStatusChange = (status) => {
    if (status === this.statusEnum.invalidEmail) {
      this.setState({
        contact_email_valid: false,
        contact_email_style: "2px solid red",
      });
    } else if (status === this.statusEnum.validEmail) {
      this.setState({
        contact_email_valid: true,
        contact_email_style: "",
      });

      this.validateContactForm();
    } else if (status === this.statusEnum.fail) {
      this.setState({
        contact_result_message:
          "Failed to send e-mail. Please contact us directly. Sorry for the inconvenience.",
        contact_result_color: "red",
      });
    } else if (status === this.statusEnum.success) {
      this.setState({
        contact_result_message: "E-mail sent. We will reply shortly.",
        contact_result_color: "green",
      });
    } else {
      // invalid data
      this.setState({
        contact_result_message: "Please enter a valid name, email and message.",
        contact_result_color: "red",
      });
    }
  };

  // Normalizes the phone input in the contact form.
  normalizePhoneInput = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  postEmail = () => {
    if (
      this.state.email_name !== "" &&
      this.state.email_message !== "" &&
      this.state.contact_email_valid
    ) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fromName: this.state.email_name,
          fromEmail: this.state.email_address,
          fromPhone: this.state.phone_number,
          message: this.state.email_message,
        }),
      };

      fetch("/api/send_email", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.onStatusChange(result.status);
        });
    } else {
      this.onStatusChange("INVALID_DATA");
    }
  };

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Validates the entire contact form.
  // If everything is valid, user can send an email.
  validateContactForm() {
    if (
      this.state.email_name !== "" &&
      this.state.email_message !== "" &&
      this.state.contact_email_valid
    ) {
      this.setState({
        contact_form_valid: true,
      });
    } else {
      this.setState({
        contact_form_valid: false,
      });
    }
  }

  reportWhatsappEvent = () => {
    ReactGA.event({
      category: "Client",
      action: "Opened WhatsApp Link",
    });
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <Col className="justify-content-center contact-form">
              <Card
                className="contact bg-gradient-secondary-light shadow"
                contact_in_view={this.state.contact_in_view}
              >
                <CardBody className="p-lg-5">
                  <h4 className="display-4 text-primary mb-2">Contact us</h4>
                  <p className="mt-0 text-dark">
                    Your project is important to us.
                    <br />
                    Leave us an e-mail and we'll quickly get in touch.
                  </p>
                  <FormGroup
                    className={classnames("mt-2", {
                      focused: this.state.nameFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-user-run" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        type="text"
                        onFocus={(e) => this.setState({ nameFocused: true })}
                        onBlur={(e) => this.setState({ nameFocused: false })}
                        name="email_name"
                        onChange={this.handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: this.state.emailFocused,
                    })}
                    style={{ border: this.state.contact_email_style }}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email address"
                        type="email"
                        onFocus={(e) => this.setState({ emailFocused: true })}
                        onBlur={(e) => this.setState({ emailFocused: false })}
                        name="email_address"
                        onChange={this.handleEmailInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: this.state.phoneNumberFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Phone number (optional)"
                        type="text"
                        onFocus={(e) =>
                          this.setState({ phoneNumberFocused: true })
                        }
                        onBlur={(e) =>
                          this.setState({ phoneNumberFocused: false })
                        }
                        name="phone_number"
                        value={this.state.phone_number}
                        onChange={this.handlePhoneInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Input
                      className="form-control-alternative"
                      cols="80"
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                      name="email_message"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                  <div className="mb-4">
                    <Button
                      block
                      className="btn-round text-secondary"
                      color="dark"
                      size="lg"
                      type="button"
                      onClick={this.postEmail}
                      disabled={!this.state.contact_form_valid}
                    >
                      Send Message
                    </Button>
                  </div>
                  {this.state.contact_result_message !== "" &&
                    this.state.contact_email_valid && (
                      <div>
                        <p
                          style={{
                            color: this.state.contact_result_color,
                          }}
                        >
                          {this.state.contact_result_message}
                        </p>
                      </div>
                    )}
                </CardBody>
              </Card>
              {this.isMobile && (
                <Card className="mt-4 mb-4 bg-gradient-secondary-light shadow">
                  <CardBody className="p-lg-5">
                    <div>
                      <p className="text-center text-dark">
                        Or send us a message on{" "}
                        <b className="text-primary">WhatsApp</b>
                      </p>
                    </div>
                    <ReactWhatsapp
                      className="mb-2 whatsapp-button btn-round text-secondary"
                      number="1-647-833-3560"
                      message="Hello, I would like information about your services."
                      onClick={this.reportWhatsappEvent}
                      element={Button}
                    >
                      <img
                        className="whatsapp-img"
                        alt="whatsapp-logo"
                        src={require("../../../assets/img/icons/whatsapp-logo-1.png")}
                      />
                    </ReactWhatsapp>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Col>
          {!this.isMobile && (
            <Col
              className="map-element"
              map_in_view={this.state.contact_in_view}
            >
              <GMap />
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default ContactForm;
