import React from "react";
import ImageGallery from "react-image-gallery";
import "./SimpleGallery.scss";

const images = [];

class SimpleGallery extends React.Component {
  isMobile = window.innerWidth <= 500;

  state = {
    isLoaded: false,
  };

  componentDidMount() {
    fetch("/portfolio")
      .then((response) => response.json())
      .then((res) => {
        res.files.forEach((element) => {
          let imageFile = {
            original: element,
            thumbnail: element,
            loading: "lazy",
            originalClass: "img-gallery",
            description: this.getDescription(element),
            originalAlt: this.getAltText(element),
          };
          images.push(imageFile);
        });

        this.setState({ isLoaded: true });
      });
  }

  getDescription(fileName) {
    const suffix = fileName.substring(fileName.length - 6, fileName.length - 4);
    switch (suffix) {
      case "HD":
        return "Housing Developments";
      case "CP":
        return "Commercial Projects";
      case "CH":
        return "Custom Housing";
      case "MF":
        return "Metal Framing";
      case "CC":
        return "Custom Ceiling Designs";
    }
  }

  getAltText(fileName) {
    const suffix = fileName.substring(fileName.length - 6, fileName.length - 4);
    switch (suffix) {
      case "HD":
        return "house development";
      case "CP":
        return "commercial project";
      case "CH":
        return "custom house";
      case "MF":
        return "metal framing area";
      case "CC":
        return "custom ceiling";
    }
  }

  render() {
    if (this.isMobile) {
      return (
        <div>
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            slideDuration={350}
            additionalClass="gallery"
            autoPlay={false}
            infinite={true}
            showThumbnails={false}
          />
          {this.state.isLoaded && (
            <div className="watermark">
              <img
                src={require("../../assets/img/logo/rv-logo-small.png")}
                alt="damasio drywall logo"
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            slideDuration={350}
            additionalClass="gallery"
            autoPlay={false}
            infinite={true}
            lazyLoad={true}
          />
          {this.state.isLoaded && (
            <div className="watermark">
              <img
                src={require("../../assets/img/logo/rv-logo-small.png")}
                alt="damasio drywall logo"
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default SimpleGallery;
